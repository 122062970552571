import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import RaderChart from "components/raderChartLoadable";
import MakerRanking from "components/makerRankingLoadable";
import Socials from "components/socials";
import TOC from "components/toc";
import AccentBox from 'components/accentBox';
import SmallBox from 'components/smallBox';
import Lineup from 'components/lineup';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "makers/dell"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    dell_wordcloud: file(relativePath: { eq: "pcmaker/dell_wordcloud.png"}) { ...normalImg },
    dell: file(relativePath: { eq: "pcmaker/dell.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`デルの評判 - コスパは最強、自分で設定できる人向けパソコン`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="dell_wordcloud" alt="デルの評判" fadeIn={false} mdxType="Image" />
    <p>{`Dellは基本的には量販店などの仲介をせずに、顧客へ直接販売するダイレクトモデルを展開するアメリカの会社である。中間マージンが低いことと世界シェアがトップクラスのため、高い価格性能比のパソコンを実現できている。`}</p>
    <p>{`一方でサポートが悪い、納期が遅い、買わなければ良かったなどの悪評も多いため、購入の踏ん切りがつかないという人も多いかもしれない。`}</p>
    <p>{`本記事では評判と実情を踏まえ、Dellがあなたにあっているのかを判断する材料を提供する。全メーカーと比較検討したい場合は`}<Link to="/pcmaker/" mdxType="Link">{`パソコンメーカーおすすめランキング`}</Link>{`も併せて参照して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "デルのラインナップと特徴",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%83%AB%E3%81%AE%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%8A%E3%83%83%E3%83%97%E3%81%A8%E7%89%B9%E5%BE%B4",
        "aria-label": "デルのラインナップと特徴 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デルのラインナップと特徴`}</h2>
    <Lineup {...props} maker="dell" mdxType="Lineup" />
    <p>{`ラインナップはデスクトップの一体型からゲーミングノートまで全てを網羅する全方位型のラインナップである。`}</p>
    <p>{`エントリークラスの
`}<em parentName="p">{`Inprison`}</em>{`、プレミアモデルの`}<em parentName="p">{`XPS`}</em>{`、ゲーミングのエントリークラスである`}<em parentName="p">{`Gシリーズ`}</em>{`、ゲーミングのハイエンドモデル`}<em parentName="p">{`AlienWare`}</em>{`とブランド名が細かく分かれている。`}</p>
    <p>{`世界の20%近いシェアを持っていることから`}<strong parentName="p"><em parentName="strong">{`特にエントリークラスのコストパフォーマンスは抜群に高い`}</em></strong>{`。`}</p>
    <p>{`またデスクトップのAlienwareはエイリアンをモチーフにしたデザインがされており、デザイン性が高い他、筐体から設計しているためエアフローの効率も良く冷却性能や静音性に優れている。`}</p>
    <SmallBox type="word" text="ラインナップを見る" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006794&type=3&subid=0" target="_blank" rel="nofollow noopener">デル (ノート)</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006794&type=3&subid=0" /> | <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006795&type=3&subid=0" target="_blank" rel="nofollow noopener">デル (デスクトップ)</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006795&type=3&subid=0" />
    </SmallBox>
    <h2 {...{
      "id": "デルの評判、総評と項目別評価",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%83%AB%E3%81%AE%E8%A9%95%E5%88%A4%E3%80%81%E7%B7%8F%E8%A9%95%E3%81%A8%E9%A0%85%E7%9B%AE%E5%88%A5%E8%A9%95%E4%BE%A1",
        "aria-label": "デルの評判、総評と項目別評価 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デルの評判、総評と項目別評価`}</h2>
    <RaderChart maker='dell' className={'top-margin'} mdxType="RaderChart" />
    <p>{`デルはアメリカ合衆国のテキサス州に本社を置く、大手パソコンメーカーで、量販店を仲介しないダイレクトモデルでパソコンを受注販売している。`}</p>
    <p>{`シェアが高いことと仲介マージンが少ないことから`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスは全メーカー中トップ`}</em></strong>{`であり、大手日系メーカーと比べると同等スペックのパソコンが３割引き程度で購入できる。`}</p>
    <p>{`一方で、海外で組み立てて日本に送ることも多いため、`}<strong parentName="p"><em parentName="strong">{`即納モデルでなければ２週間から１ヵ月程度は覚悟しなければならない`}</em></strong>{`。幸いにも納期の目安を購入時には確認することができるので、納得した上での注文が可能である。`}</p>
    <p>{`また製品サポートの多くは日本人ではないため、修理などの対応で戸惑ることもある。ただ`}<strong parentName="p"><em parentName="strong">{`カスタマーサポートの仕組み自体はしっかりとしているため、私も２回利用したことがあるが、要件さえ明確に伝えられれば特段問題はない`}</em></strong>{`。少し聞き取りづらいという程度である。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`製品自体は余計なソフトが入っていない`}</em></strong>{`ため、ある程度ITのリテラシーが高い人必要があるが、ネットで製品を注文できるぐらいのリテラシーが備わっていれば行いたい作業を調べて使いこなすことに問題は無いだろう。`}</p>
    <AccentBox title="デルの項目別評価" mdxType="AccentBox">
  <li><span className="bold">コストパフォーマンス</span>・・・全メーカー中で最もコスパが高い。レノボとはいい勝負</li>
  <li><span className="bold">サポート</span>・・・<a href="https://www.dell.com/ja-jp/shop/inquiries/cp/contact-home" target="_blank" rel="nofollow noopenner">サポートサイト</a>より、電話/Lineチャットなどが可能。一通りのことは問題はない。</li>
  <li><span className="bold">デザイン</span>・・・たまにカッコいいモデルも見つかる程度</li>
  <li><span className="bold">人気</span>・・・当サイトでの人気。マウスコンピュータの次に売れているメーカー、やはりコスパが魅力</li>
  <li><span className="bold">安定性</span>・・・米国の大企業であり業績も堅調。安定した運営が見込める</li>
    </AccentBox>
    <h2 {...{
      "id": "実際に売れているモデルはこれ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%9F%E9%9A%9B%E3%81%AB%E5%A3%B2%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AF%E3%81%93%E3%82%8C",
        "aria-label": "実際に売れているモデルはこれ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`実際に売れているモデルはこれ`}</h2>
    <p>{`本サイトを経由して実際によく売れているモデルを紹介する。当サイトはパソコンにそれほど詳しくはないが、インターネットで買い物ができるなど標準的なITリテラシーを持った人をメインターゲットとしており、比較して堅実に買い物をしている人が多いと思われる。そのような層で人気が高いパソコンは下記の２つである。`}</p>
    <h3 {...{
      "id": "InprisonのノートPC",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Inprison%E3%81%AE%E3%83%8E%E3%83%BC%E3%83%88PC",
        "aria-label": "InprisonのノートPC permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InprisonのノートPC`}</h3>
    <p>{`InprisonはDellのエントリークラスモデルのパソコンであり、デスクトップ/ノート共に展開しているが、そのうちノートパソコンの売れ行きが非常に良い。実際当サイトで売れている７割はこのモデルである。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`コスパの高いエントリークラスでのコスパの高いメーカーという事でコスパオブコスパな製品`}</em></strong>{`と言える。`}</p>
    <p>{`サイズとしては15.6インチが最も売れ、時点で14.1インチモデルがよく売れている。安くて性能が良いパソコンをお求めであるなら、まずは確認しておきたいモデルである。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10008673&type=3&subid=0" rel="nofollow noopener" target="_blank">Dell Inspiron</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10008673&type=3&subid=0" />
    </SmallBox>
    <h2 {...{
      "id": "デルの３大悪評",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%83%AB%E3%81%AE%EF%BC%93%E5%A4%A7%E6%82%AA%E8%A9%95",
        "aria-label": "デルの３大悪評 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デルの３大悪評`}</h2>
    <p>{`悪評は購入時の期待値と実際の乖離から生じるものである。よって`}<strong parentName="p"><em parentName="strong">{`購入前に齟齬が出そうな点をあらかじめ抑えておくことが重要`}</em></strong>{`となる。ここではネットでの評判を抽出し、その妥当性についてコメントしていく。`}</p>
    <h3 {...{
      "id": "納期が遅い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%B4%8D%E6%9C%9F%E3%81%8C%E9%81%85%E3%81%84",
        "aria-label": "納期が遅い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`納期が遅い`}</h3>
    <p>{`その通りである。しかし納期の目安は製品情報に記載されているため、納得した上で選ぶことができるはずである。また即日出荷のモデルもあるため、時に割高になる可能性はあるが、早くパソコンが欲しければそちらを選べば良い。`}</p>
    <h3 {...{
      "id": "サポートが最悪",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%8C%E6%9C%80%E6%82%AA",
        "aria-label": "サポートが最悪 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サポートが最悪`}</h3>
    <p>{`Dellのカスタマーサービスに電話をすると片言の外国人が対応する事が多い。これはより人件費の安い国へ電話対応を外注して、総コストを抑えようという試みの一貫である。`}</p>
    <p>{`日本人はお客様は神様という精神で対応を期待しがちであるが、これはグローバルスタンダードではないし、おもてなしの文化を海外のサポートに求めるというのは酷な話であろう。`}</p>
    <p>{`ともあれ、サポート体制自体はしっかりと整っているので、まともな要望であればキチンと対応してくれるはずである。`}</p>
    <h3 {...{
      "id": "故障率が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%95%85%E9%9A%9C%E7%8E%87%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "故障率が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`故障率が高い`}</h3>
    <p>{`ネットで故障率が高いと良く言われているが嘘である。エントリーモデルの販売量が非常に多いため、はずれを引いた人の声が大きくなっているだけである。`}</p>
    <p>{`工場で生産コストを抑えるためには、大量に生産することと同時に不良品が出ない確率である歩留まり率を高めていくことが重要となる。特に利益の薄いエントリーモデルに関しては歩留まり率は利益に直結するため可能な限り高めなければビジネスにはならない。`}</p>
    <p>{`という事なので大量生産されているパソコンほど、故障率は低くなるのが普通である。Dellのパソコンで故障率が高い原因はまず見つからない。`}</p>
    <h2 {...{
      "id": "デルの評判まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%83%AB%E3%81%AE%E8%A9%95%E5%88%A4%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "デルの評判まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デルの評判まとめ`}</h2>
    <p>{`以上、`}<strong parentName="p"><em parentName="strong">{`ダイレクトモデルと大量生産により圧倒的な価格競争力を持つアメリカの会社がデル`}</em></strong>{`である。商品のラインナップの確認や購入を検討している場合は下記から公式サイトへ飛ぶことができる。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006794&type=3&subid=0" target="_blank" rel="nofollow noopener">デル (ノート)</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006794&type=3&subid=0" /> | <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006795&type=3&subid=0" target="_blank" rel="nofollow noopener">デル (デスクトップ)</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006795&type=3&subid=0" />
    </SmallBox>
    <h2 {...{
      "id": "他社との比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BB%96%E7%A4%BE%E3%81%A8%E3%81%AE%E6%AF%94%E8%BC%83",
        "aria-label": "他社との比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`他社との比較`}</h2>
    <p>{`パソコンの種類や用途により得意なメーカーは分かれるため、当サイトでは選択肢に応じたランキングを制作している。`}</p>
    <p>{`デフォルトは当サイトの人気順であるが、探している種類やこだわりがあれば選択し、自分にあったパソコンを探してほしい。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`デルと似ているパソコンとしてはレノボやパソコン工房を同時に確認したい`}</em></strong>{`。リンクをクリックすると公式サイトへ移動します。`}</p>
    <MakerRanking highlight='dell' mdxType="MakerRanking" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      